<template>
  <div class="coupe-de-france-du-potager-evaluation max-w-2xl mt-1">

    <div class="w-100 d-flex align-items-center justify-content-between">
      <b-button
          :variant="$store.state.appConfig.layout.skin == 'dark' ? 'outline-secondary' : 'outline-primary' "
          @click="$router.push({name: 'coupe-de-france-du-potager'})">
        <feather-icon
            class="mr-50"
            icon="LogoutIcon"
            size="16"/>
        <span class="align-middle"
            :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-secondary' : 'text-primary' ">
          {{ $t('evaluation.saveAndQuit') }}
        </span>
      </b-button>
      <b-button
          class="p-0"
          :variant="$store.state.appConfig.layout.skin == 'dark' ? 'flat-secondary' : 'flat-primary' "
          @click="helpPopUp = true">
        <feather-icon
            icon="HelpCircleIcon"
            size="24"/>
      </b-button>
    </div>

    <div class="w-100 d-flex align-items-center justify-content-center">
      <img
          alt="logo coupe du monde du potager"
          class="max-w-xs"
          :src="logoPath">
    </div>

    <b-alert show class="text-center max-w-md p-1" :variant="$store.state.appConfig.layout.skin == 'dark' ? 'secondary' : 'primary'">
      {{ $t('evaluation.completionDeadline', { date: quiz ? $parseDate(quiz.end_date) : '' }) }}
    </b-alert>
    <b-alert show class="text-center max-w-md p-1" :variant="$store.state.appConfig.layout.skin == 'dark' ? 'secondary' : 'primary'" v-if="$store.state.cfp.currentQuizAnswerer">
      {{ $t('evaluation.mandatoryQuestionsResponse', { answered: $store.state.cfp.currentQuizAnswerer.answered_mandatory_count, total: $store.state.cfp.currentQuizAnswerer.mandatory_count }) }}
    </b-alert>
    <CFPCategories :quiz="quiz"/>

    <div class="my-3 d-flex flex-column align-items-center">
      <b-button
          class="mb-50"
          :variant="$store.state.appConfig.layout.skin == 'dark' ? 'secondary' : 'primary' "
          @click="welcomePopUp = true">
        <feather-icon
            :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-primary' : 'text-white' "
            class="mr-50"
            icon="FileTextIcon"
            size="16"/>
        <span class="align-middle" :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-primary' : 'text-white'">
          {{ $t('evaluation.welcomeMessageAndInstructions') }}
        </span>
      </b-button>

      <b-button
          class="mb-50"
          variant="secondary"
          @click="helpSheetsPopUp = true"
          v-if="$i18n.locale==='fr'">
        <feather-icon
            class="mr-50 text-primary"
            icon="FileTextIcon"
            size="16"
        />
        <span class="align-middle text-primary"> Fiches pratiques </span>
      </b-button>
    </div>

    <b-modal
        v-model="welcomePopUp"
        centered
        hide-footer
        title="Bienvenue">
      <CFPIntro class="text-center"/>
    </b-modal>

    <b-modal
        v-model="helpSheetsPopUp"
        centered
        hide-footer
        title="Fiches pratiques">
      <div class="flex-column">
        <h4 class="font-weight-bold">
          La biodiversité c'est carré
        </h4>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="$openLinkJaya('https://hortilio.fr/fiches-pratiques/13')">
          <feather-icon
              class="mr-50"
              icon="ExternalLinkIcon"
              size="16"/>
          <span class="align-middle"> Réussir ses semis </span>
        </b-button>
        <br>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="$openLinkJaya('https://hortilio.fr/fiches-pratiques/15')">
          <feather-icon
              class="mr-50"
              icon="ExternalLinkIcon"
              size="16"/>
          <span class="align-middle"> Fabriquer un abri à insecte </span>
        </b-button>

        <h4 class="font-weight-bold mt-1">
          Ça nous nourrit
        </h4>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="$openLinkJaya('https://hortilio.fr/fiches-pratiques/3')">
          <feather-icon
              class="mr-50"
              icon="ExternalLinkIcon"
              size="16"/>
          <span class="align-middle">  Les déchets végétaux </span>
        </b-button>
        <br>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="$openLinkJaya('https://hortilio.fr/fiches-pratiques/8')">
          <feather-icon
              class="mr-50"
              icon="ExternalLinkIcon"
              size="16"/>
          <span class="align-middle"> Le compost </span>
        </b-button>

        <h4 class="font-weight-bold mt-1">
          Mon bo potager
        </h4>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="$openLinkJaya('https://landestini.org/nous-vous-guidons-2022/')">
          <feather-icon
              class="mr-50"
              icon="ExternalLinkIcon"
              size="16"/>
          <span class="align-middle">  Landestini : Nous vous guidons </span>
        </b-button>

        <h4 class="font-weight-bold mt-1">
          On est prêts
        </h4>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="$openLinkJaya('https://hortilio.fr/fiches-pratiques/14')">
          <feather-icon
              class="mr-50"
              icon="ExternalLinkIcon"
              size="16"/>
          <span class="align-middle"> Le sol au potager </span>
        </b-button>
        <br>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="$openLinkJaya('https://hortilio.fr/fiches-pratiques/6')">
          <feather-icon
              class="mr-50"
              icon="ExternalLinkIcon"
              size="16"/>
          <span class="align-middle"> Gestion de l'eau </span>
        </b-button>

        <h4 class="font-weight-bold mt-1">
          Ensemble on va plus loin
        </h4>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="$openLinkJaya('https://landestini.org/nous-vous-guidons-2022/')">
          <feather-icon
              class="mr-50"
              icon="ExternalLinkIcon"
              size="16"/>
          <span class="align-middle">  Landestini : Nous vous guidons </span>
        </b-button>

        <h4 class="font-weight-bold mt-1">
          J'apprends je partage
        </h4>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="$openLinkJaya('https://landestini.org/nous-vous-guidons-2022/')">
          <feather-icon
              class="mr-50"
              icon="ExternalLinkIcon"
              size="16"/>
          <span class="align-middle">  Landestini : Nous vous guidons </span>
        </b-button>
      </div>
      <b-modal
          v-if="activeSheet"
          v-model="helpSheetPopUp"
          :title="activeSheet.title"
          centered
          hide-footer>
        <HelpSheet :sheet="activeSheet"/>
      </b-modal>
    </b-modal>

    <b-modal
        v-model="helpPopUp"
        centered
        hide-footer
        :title="$t('help.needHelp')">
      <CFPHelp/>
    </b-modal>
  </div>
</template>

<script>
import CFPIntro from '@/components/cfp/CFPIntro.vue'
import CFPCategories from '@/components/cfp/CFPCategories.vue'
import {BButton, BModal, BAlert} from "bootstrap-vue";
import HelpSheet from "@/components/help-sheets/HelpSheet";
import CFPHelp from "@/components/cfp/CFPHelp";
import logoEn from '@/assets/images/cfp/logo_cfp_en.png';
import logoFr from '@/assets/images/cfp/logo_coupe.png';
import logoEs from '@/assets/images/cfp/logo_cfp_es.png';

export default {
  components: {
    BButton,
    BModal,
    BAlert,
    CFPCategories,
    CFPIntro,
    HelpSheet,
    CFPHelp,
  },
  data() {
    return {
      welcomePopUp: false,
      helpPopUp: false,
      helpSheetsPopUp: false,
      helpSheetPopUp: false,
      activeSheet: null,
      landestiniContactEmail: 'coupedefrancedupotager@landestini.org',
      quiz: null,
      logos: { en: logoEn, fr: logoFr, es: logoEs }
    }
  },
  computed: {
    logoPath() {
      const currentLang = this.$i18n.locale; // Assuming you have access to $i18n object
      return this.logos[currentLang] || this.logos.en;
    }
  },
  mounted() {
    this.$store.dispatch("cfp/fetchQuizType", {year: new Date().getFullYear()}, {root: true})
        .then(() => {
          const quizType = this.$store.state.cfp.quizType
          const id = quizType.find((e) => (e.type === this.$store.state.account.user.category && e.language === this.$store.state.account.user.language)).id
          this.$store.dispatch("cfp/retrieveQuiz", {id: id})
              .then(() => {
            this.quiz = this.$store.state.cfp.currentQuiz
          })
          .catch(() => {
            this.$notify(
                {
                  title: this.$t('Error'),
                  text: "Le questionnaire n'a pas pu être récupéré",
                  time: 3000,
                  color: "danger"
                }
            )
          })
        })
        .catch(() => {
          this.$notify(
              {
                title: this.$t('Error'),
                text: "Le type de questionnaire n'a pas pu être récupéré",
                time: 3000,
                color: "danger"
              }
          )
        })

  }
}
</script>


<style lang="scss">
.coupe-de-france-du-potager-evaluation {
  p {
    padding-top: 1rem;
  }
}
</style>
